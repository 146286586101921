import React from "react"
import "../sass/main.scss"
import { Layout, SEO, HeadSection } from "../components"
import { Row, Col, Container } from "react-bootstrap"

const SolveEnginePage = () => {
  const headTitle = (): string => {
    return "Satalia SolveEngine"
  }
  const subTitle = (): string => {
    return "Optimisation as a Service."
  }
  const description = (): string => {
    return "As of 30th September 2020 free access to Satalia SolveEngine will be paused for all users. The next step for us is to take the Satalia SolveEngine Beta offline whilst we work on developing the next iteration."
  }
  return (
    <Layout step={3}>
      <SEO
        title="Satalia SolveEngine | Optimisation as a Service"
        description="Satalia SolveEngine aggregates thousands of algorithms and accelerates our research. Subscribe to be the first to know when we launch solving as a service."
        twitterTitle="Satalia SolveEngine | Optimisation as a Service"
        twitterDescription="Satalia SolveEngine aggregates thousands of algorithms and accelerates our research. Subscribe for updates."
        openGraphTitle="Satalia SolveEngine | Optimisation as a Service"
        openGraphDescription="Optimisation as a Service."
        meta={[{ name: "robots", content: "index, follow" }]}
      />
      <section id="case-study" className="mb-25">
        <Container>
          <HeadSection
            title={headTitle()}
            subtitle={subTitle()}
            description={description()}
          ></HeadSection>
        </Container>
      </section>
      <section id="case-study-detail" className="pb-75">
        <Container className="position-relative">
          <Row>
            <Col xs="3" sm="8" md="8" lg="6" className="offset-lg-4">
              <p>
                Whilst we are doing this we want to make ourselves available to
                researchers who are developing optimisation applications to
                improve the quality of life for others. If that is you then we
                want to hear from you.
              </p>
              <br />
              <p className="form-text">
                Please send your proposal to us at{" "}
                <a href="mailto:solveengine@satalia.com">
                  solveengine@satalia.com
                </a>{" "}
                and we will be back in contact to see how we might be able to
                support you and your project.
              </p>
              <br />
              <p>
                We are also keen to hear from developers who are open to
                exploring our premium services to support their optimisation
                applications, if this is you please email us.
              </p>
              <br />
              <p className="form-text">
                For now please email all queries and feedback to{" "}
                <a href="mailto:solveengine@satalia.com">
                  solveengine@satalia.com
                </a>
                , we are looking forward to hearing from you.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default SolveEnginePage
